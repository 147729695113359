import React from "react"
import { graphql } from "gatsby"
import styled from "styled-components"

// Custom Hooks
import { useLocale } from "../../hooks/useLocale"

// Components
import Seo from "../../components/Seo"
import Intro from "../../components/Intro"
import Explanation from "../../components/Explanation"
import Columns from "../../components/Columns"
import ServicesBlock from "../../components/ServicesBlock"
import LogoBanner from "../../components/LogoBanner"
import TeamComponent from "../../components/TeamComponent"

// Styles
import colors from "../../styles/colors"

// Constants
import { paths } from "../../constants/paths"

// Types
import { ICareerFrameworkPage } from "../../prismic-types/career-framework"
import { IntroVariant } from "../../prismic-types/homepage"

interface IProps {
  content: ICareerFrameworkPage
}

const CareerFramework: React.FC<IProps> = ({ content }) => {
  const { getLocalizedLink } = useLocale()
  const slug = "services/career-framework"

  return (
    <>
      <Seo
        title={content.meta.title}
        description={content.meta.description}
        dateModified={content.meta.modifiedDate}
        datePublished={content.meta.publishedDate}
        lang={content.meta.lang}
        slug={slug}
        imageUrl={content.intro.desktopImg.imageUrl}
        breadCrumbItems={[
          { name: "Services", url: "services" },
          { name: "Career Framework", url: slug },
        ]}
      />
      <Intro
        {...content.intro}
        buttonVariant="tertiary"
        buttonLink={getLocalizedLink(paths.demo)}
        buttonLabel={content.intro.button.label}
        color="purple"
        titleTag="h3"
        shapeColor={colors.white}
      />

      <StackColumns>
        <StyledExplanation
          pageGroup="Services"
          {...content.explanation}
          buttonVariant="primary"
        />
        <StyledColumns
          id="career-framework-columns"
          backgroundColor={colors.white}
          columns={content.columns}
          justifyColumn="start"
          color={colors.purpleLight}
        />
      </StackColumns>
      <TeamComponent
        team={content.team}
        title="The people that will help you get it done"
      />
      <StyledLogoBanner {...content.logos} />
      <StyledServicesBlock {...content.services} />
    </>
  )
}

const StackColumns = styled.div`
  background: linear-gradient(
      180deg,
      ${colors.white} 0%,
      ${colors.blueSoft} 100%
    ),
    ${colors.greyLight};
`

const StyledExplanation = styled(Explanation)`
  padding-bottom: 0px;
  background: none;
`

const StyledColumns = styled(Columns)`
  background: none;
`

const StyledLogoBanner = styled(LogoBanner)`
  background: ${colors.main["700"]};
`

const StyledServicesBlock = styled(ServicesBlock)`
  background-color: ${colors.main["800"]};
  padding-top: 80px;
`

export const query = graphql`
  query CareerFrameworkPage($locale: String!) {
    allPrismicEvaluationDetail(filter: { lang: { eq: $locale } }) {
      edges {
        node {
          data {
            testimonials {
              logo {
                alt
                fluid {
                  aspectRatio
                  base64
                  sizes
                  src
                  srcSet
                  srcSetWebp
                  srcWebp
                }
              }
            }
          }
        }
      }
    }
    prismicCareerFrameworkPage(lang: { eq: $locale }) {
      lang
      first_publication_date
      last_publication_date
      data {
        # Meta
        meta_title
        meta_description

        # Intro
        intro_title {
          text
        }
        intro_text {
          html
        }
        intro_label
        intro_cta_label
        intro_cta_link
        intro_image {
          alt
          url
          fluid {
            aspectRatio
            base64
            sizes
            src
            srcSet
            srcSetWebp
            srcWebp
          }
        }

        cta_section_title {
          html
        }
        cta_section_text {
          html
        }

        # How we do
        how_we_do_list {
          title {
            text
          }
          subtitle {
            text
          }
          text {
            html
          }
          image {
            alt
            fluid {
              aspectRatio
              base64
              sizes
              src
              srcSet
              srcSetWebp
              srcWebp
            }
          }
        }

        # Logos
        logos_label

        # Services block
        services_title {
          html
        }
      }
    }
    prismicManifestoPageBodyTeamMember {
      items {
        image {
          url
        }
        member_name {
          text
        }
        member_role {
          text
        }
      }
    }
    allPrismicHomePage(filter: { lang: { eq: $locale } }) {
      edges {
        node {
          data {
            slides {
              service_type
              label
              level
              title {
                text
              }
              text {
                html
              }
              button_label
              button_link
              image {
                alt
                fluid {
                  aspectRatio
                  base64
                  sizes
                  src
                  srcSet
                  srcSetWebp
                  srcWebp
                }
              }
            }
          }
        }
      }
    }
  }
`

export default ({ data }: any) => {
  const {
    data: raw,
    lang,
    first_publication_date,
    last_publication_date,
  } = data.prismicCareerFrameworkPage
  const logoData =
    data.allPrismicEvaluationDetail.edges[0].node.data.testimonials

  const team = data.prismicManifestoPageBodyTeamMember.items.map(
    (teamMember: any) => ({
      image: teamMember.image,
      memberName: teamMember.member_name[0].text,
      memberRole: teamMember.member_role[0].text,
    })
  )

  const meta = {
    title: raw.meta_title,
    description: raw.meta_description,
    publishedDate: first_publication_date,
    modifiedDate: last_publication_date,
    lang,
  }

  const intro = {
    title: raw.intro_title?.text,
    text: raw.intro_text?.html,
    label: raw.intro_label,
    desktopImg: {
      alt: raw.intro_image?.alt,
      url: raw.intro_image?.fluid,
      imageUrl: raw.intro_image?.url,
    },
    button: {
      label: raw.intro_cta_label,
      link: raw.intro_cta_link,
    },
    variant: "page" as IntroVariant,
  }

  const explanation = {
    title: raw.cta_section_title?.html,
    text: raw.cta_section_text?.html,
  }

  const columns = raw.how_we_do_list.map((item: any) => ({
    title: item.title.text,
    subTitle: item.subtitle.text,
    text: item.text.html,
    image: {
      alt: item.image.alt,
      url: item.image.fluid,
    },
  }))

  const logos = {
    label: raw.logos_label,
    logos: logoData.map((testimonial: any) => ({
      alt: testimonial.logo?.alt,
      url: testimonial.logo?.fluid,
    })),
  }

  const formattedServices = data.allPrismicHomePage.edges[0].node.data.slides
    .filter((slide: any) => slide.button_link !== "career")
    .map((slide: any) => ({
      title: slide.title.text,
      type: slide.service_type,
      label: slide.label,
      level: slide.level,
      text: slide.text.html,
      button: {
        link: slide.button_link,
        label: slide.button_label,
      },
      image: {
        alt: slide.image?.alt,
        url: slide.image?.fluid,
      },
    }))

  const content = {
    meta,
    intro,
    explanation,
    columns,
    logos,
    services: {
      title: raw.services_title?.html,
      slides: formattedServices,
    },
    team,
  }

  return <CareerFramework content={content} />
}
